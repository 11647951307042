import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "userAccount _max940"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Benutzer Daten")]), _vm.userData.userRole == 'no' ? _c('div', {
    staticClass: "tac"
  }, [_vm.userData.userRole == 'no' ? _c('p', {
    staticClass: "_redp bold tac"
  }, [_vm._v("Ihnen sind noch keine Rolle und keine Rechte vergeben worden.")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "card-content"
  }, [_vm._isAdmin || _vm._isSv ? _c('span', [_c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Titel:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userData.akktitle,
      expression: "userData.akktitle"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.userData.akktitle
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userData, "akktitle", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Vorname:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userData.firstname,
      expression: "userData.firstname"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.userData.firstname
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userData, "firstname", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Nachname:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userData.lastname,
      expression: "userData.lastname"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.userData.lastname
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userData, "lastname", $event.target.value);
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Email:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userData.email,
      expression: "userData.email"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.userData.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userData, "email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Rolle:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userData.userRole,
      expression: "userData.userRole"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.userData.userRole
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userData, "userRole", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Firebase Id:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userDataFb.uid,
      expression: "userDataFb.uid"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.userDataFb.uid
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userDataFb, "uid", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("CRM User Id:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userDataCrm.crmUserId,
      expression: "userDataCrm.crmUserId"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.userDataCrm.crmUserId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userDataCrm, "crmUserId", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("CRM Kontakt Id:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userDataCrm.crmContactId,
      expression: "userDataCrm.crmContactId"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.userDataCrm.crmContactId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.userDataCrm, "crmContactId", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("CRM User:")]), _vm.userDataCrm.isCrmUser ? _c('i', {
    staticClass: "icon-checkmark"
  }) : !_vm.userDataCrm.isCrmUser ? _c('i', {
    staticClass: "icon-times"
  }) : _vm._e()])])]), _vm._isAdmin || _vm._isSv ? _c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('input-field', {
    attrs: {
      "label": "Präf. Nachrichten per:"
    }
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userData.preferredNotificationTransmission,
      expression: "userData.preferredNotificationTransmission"
    }],
    staticClass: "input",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.userData, "preferredNotificationTransmission", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "email"
    }
  }, [_vm._v("per Email")]), _c('option', {
    attrs: {
      "value": "webapp"
    }
  }, [_vm._v("per Webapp")])])]), _vm.$features['messages-as-email'] ? _c('div', {
    staticClass: "section"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "In-App Nachrichten auch als E-Mail erhalten"
    },
    model: {
      value: _vm.userData.receiveMessagesAsEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "receiveMessagesAsEmail", $$v);
      },
      expression: "userData.receiveMessagesAsEmail"
    }
  })], 1) : _vm._e(), _vm._isAdmin || _vm._isSv ? [_c('div', {
    staticClass: "section"
  }, [_c('div', [_c('v-checkbox', {
    attrs: {
      "label": "Allianz Akte laden"
    },
    model: {
      value: _vm.userData.loadAllianz,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "loadAllianz", $$v);
      },
      expression: "userData.loadAllianz"
    }
  })], 1), _c('div', [_c('v-checkbox', {
    attrs: {
      "label": "CRM Akte laden"
    },
    model: {
      value: _vm.userData.loadCrm,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "loadCrm", $$v);
      },
      expression: "userData.loadCrm"
    }
  })], 1), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('div', [_c('v-checkbox', {
    attrs: {
      "label": "nur meine eigene Akte laden (CRM und Allianz)"
    },
    model: {
      value: _vm.userData.loadOnlyMy,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "loadOnlyMy", $$v);
      },
      expression: "userData.loadOnlyMy"
    }
  })], 1), _c('div', {
    staticClass: "spacer _vertical _small"
  })]), _c('div', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "bold"
  }, [_vm._v("Offline Speicher")]), _c('div', [_c('v-checkbox', {
    attrs: {
      "label": "Offline Speicher an diesem Gerät aktivieren"
    },
    model: {
      value: _vm.isUsingOffline,
      callback: function callback($$v) {
        _vm.isUsingOffline = $$v;
      },
      expression: "isUsingOffline"
    }
  })], 1)]), _vm._m(1), _vm._m(2), _vm._m(3)] : _vm._e()], 2), _c('div', {
    class: {
      _opened: _vm.isChanged
    },
    attrs: {
      "id": "savebtn"
    },
    on: {
      "click": _vm.saveUserData
    }
  }, [_c('i', {
    staticClass: "icon-upload"
  }), _c('br'), _c('span', {
    staticClass: "bold"
  }, [_vm._v("SPEICHERN")])])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Eigene Einstellungen")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('p', [_c('small', [_vm._v("Wenn ständige Internetverbindung besteht (z.B. bei einem Stand-PC oder Laptop),dann macht es keinen Sinn diese Funktion zu aktivieren. Diese Funktion ist eher für Tablets bei Besichtigungen gedacht um Daten offline zu speichern.")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('p', [_c('small', [_c('b', [_vm._v("ACHTUNG")]), _vm._v(": Bei zu vielen offenen Tabs mit dieser Webapp im Browser sollte diese Funktion jedoch abgeschaltet sein,da die Webapp sonst sehr langsam laufen könnte.")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('p', [_c('small', [_vm._v("Nach Änderung, bitte die Seite neu laden!")])]);
}];
export { render, staticRenderFns };